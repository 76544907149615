@import "../styles/variables";
@import "../styles/vendor/breakpoints";

.layoutModal {
  text-align: center;
  padding: 2rem;
  
  .statusHeading {
    font-weight: $headings-font-weight;
  }

  .statusMessage {
    margin-top: 1rem;
    font-size: 1rem;
  }

  .signinButton {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .buttonWrapper {
    margin-top: 1rem;
  }
}

.parent {
	display: flex;
  padding: 0 !important;

	.loader {
		display: flex;
		flex: 1 1 auto;
		align-items: center;

		font-size: 2rem;
	}
}