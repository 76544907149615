html {
	scroll-behavior: smooth;
}

body {
	margin: 0; // 1
	overflow-x: hidden;

	color: $body-color;
	font-weight: $font-weight-base;
	font-size: $font-size-base; // FIXME:
	font-family: $font-family-base;
	line-height: $line-height-base;
	text-align: left; // 3

	background-color: $body-bg; //
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

html,
body,
#___gatsby,
#gatsby-focus-wrapper,
.page {
	height: 100%;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.page {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	align-items: stretch;
	height: 100%;
	margin: 0;
	padding: 0;

	&__header,
	&__footer {
		flex: 0 0 auto;
	}

	&__container {
		flex: 1 0 auto;
	}

	&__content {
		@include swg-container;
		@include media-breakpoint-up(sm) {
			padding-right: $page-padding;
			padding-left: $page-padding;
		}
		@include media-breakpoint-down(xs) {
			padding-right: $page-padding/2;
			padding-left: $page-padding/2;
		}
	}
}

.wrapper__content {
	@include swg-container;
}

.margin-regular {
	margin: 2.188rem 0;
}

.inline-flex {
	display: inline-flex;
}

.items-center {
	align-items: center;
}

.relative {
	position: relative;
}

.margin-bottom-regular {
	margin-bottom: $page-padding;
}

.margin-bottom-lg {
	margin-bottom: $page-padding * 2;
}

.margin-top-regular {
	margin-top: $page-padding;
}

.margin-top-lg {
	margin-top: $page-padding * 2;
}

.no-underline {
	text-decoration: none;
	@media (hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

.sr-only {
	@include sr-only;
}

.link-color {
	color: $swg-tide;
}

// From Bootstrap reboot
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: $headings-margin-bottom;
}

// p {
// 	margin-top: 0;
// 	margin-bottom: $paragraph-margin-bottom;
// }

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

fieldset {
	// Browsers set a default `min-width: min-content;` on fieldsets,
	// unlike e.g. `<div>`s, which have `min-width: 0;` by default.
	// So we reset that to ensure fieldsets behave more like a standard block element.
	// See https://github.com/twbs/bootstrap/issues/12359
	// and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
	min-width: 0;
	margin: 0;
	// Reset the default outline behavior of fieldsets so they don't affect page layout.
	padding: 0;

	border: 0;
}

select {
	word-wrap: normal;
}

input,
button,
select,
optgroup,
textarea {
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible; // Show the overflow in Edge
}

select {
	text-transform: none; // Remove the inheritance of text transform in Firefox
}

button:focus:not(:focus-visible) {
	outline: 0;
}

table {
	border-collapse: collapse;
}

// Remove the inheritance of word-wrap in Safari.
//
// Details at https://github.com/twbs/bootstrap/issues/24990

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
	appearance: none; // 2
}

a {
	color: inherit;
}

// Opinionated: add "hand" cursor to non-disabled button elements.
@if $enable-pointer-cursor-for-buttons {
	button,
	[type="button"],
	[type="reset"],
	[type="submit"] {
		&:not(:disabled) {
			cursor: pointer;
		}
	}
}

input::-ms-clear {
	display: none;
}

p:empty {
	display: none;
}
