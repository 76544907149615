@import "../../styles/variables";
@import "../../styles/vendor/breakpoints";
@import "../../styles/mixins";
@import "../../styles/functions";

// Stripped down version of "rc-picker/assets/index.css";
.swgPicker {
	$prefix: &;
	position: relative;

	display: flex;
	flex: 1 0 auto;
	width: 100%;

	&-suffix {
		display: flex;
		flex: 0 1 0.75rem;
		align-items: center;
		justify-content: center;
		min-width: 1.75rem;
		margin-left: auto;
		padding: 0.5rem;

		color: $swg-tide;

		.calendarIcon {
			font-size: 0.75rem;
		}
	}

	&-panels {
		@include media-breakpoint-up(sm) {
			display: flex;
		}
	}

	&-panel {
		display: block;
		flex: 0 0 auto;

		@include media-breakpoint-up(sm) {
			display: flex;
			justify-content: center;
			margin-bottom: 0;
		}

		&-container {
			display: block;
			padding: 0.75rem;

			background-color: $swg-white;
			border: 1px solid $swg-tide;
			border-radius: 4px;
			box-shadow: 0 9px 16px rgba(#000, 0.16);

			transition: margin 0.3s;

			@include media-breakpoint-up(sm) {
				border-radius: 0 0 4px 4px;
			}
		}
	}

	&-header {
		position: relative;

		display: flex;

		@include media-breakpoint-up(sm) {
			position: initial;
		}

		&-super-prev-btn,
		&-super-next-btn,
		&-prev-btn,
		&-next-btn {
			position: absolute;

			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.5rem;
			height: 1.5rem;
			margin: 0;
			padding: 0;

			background: $swg-pearl;
			border: 1px solid $swg-pebble;
			cursor: pointer;

			@include media-breakpoint-up(sm) {
				top: auto;

				width: rem(40px);
				height: rem(30px);
			}
		}

		&-super-prev-btn,
		&-prev-btn {
			right: 2rem;

			@include media-breakpoint-up(sm) {
				right: auto;
				left: 0.75rem;
			}
		}

		&-super-next-btn,
		&-next-btn {
			right: 0;

			@include media-breakpoint-up(sm) {
				right: 0.75rem;
			}
		}

		.navigationIcon {
			font-size: 0.75rem;

			&Prev {
				transform: rotate(270deg);

				@include media-breakpoint-up(sm) {
					transform: rotate(180deg);
				}
			}

			&Next {
				transform: rotate(90deg);

				@include media-breakpoint-up(sm) {
					transform: none;
				}
			}
		}

		&-view {
			flex: auto;
			padding: 0;

			text-align: left;

			@include media-breakpoint-up(sm) {
				text-align: center;
			}

			> button {
				@include btn-reset();
				color: $swg-sun;
				font-weight: 700;
				font-size: rem(18px);
			}
		}
	}

	&-cell {
		padding: 0;

		color: $swg-pebble;

		cursor: pointer;

		transition: background 0.3s, border 0.3s;

		&:hover {
			color: $swg-white;

			background: $swg-sun;
		}

		&-inner {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&-cell-range-hover {
		color: inherit;

		background-color: $swg-pearl;
	}

	&-cell-in-view {
		color: $swg-stone;

		&#{$prefix}-cell-range-start,
		&#{$prefix}-cell-range-end,
		&#{$prefix}-cell-selected {
			color: $swg-white;

			background-color: $swg-sun;
		}

		&#{$prefix}-cell-in-range {
			color: inherit;

			background: $swg-pearl;

			&:hover {
				color: $swg-white;

				background: $swg-sun;
			}
		}
	}

	&-cell-today {
		border: 1px solid $swg-stone;
	}

	&-cell-disabled {
		color: #ccc;

		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			color: #ccc;

			background: transparent;
		}
	}

	&-decade-panel,
	&-year-panel,
	&-month-panel,
	&-date-panel {
		display: flex;
		flex: 0 1 auto;
		flex-direction: column;

		@include media-breakpoint-up(sm) {
			padding: 0 2.75rem;
		}

		#{$prefix}-content {
			width: 100%;

			font-size: 1rem;
			text-align: center;

			table-layout: fixed;
			border-collapse: collapse;
			border-spacing: 0;

			@include media-breakpoint-up(sm) {
				width: auto;
			}

			th {
				font-weight: 400;
				font-size: 1rem;
			}
		}
	}

	&-decade-panel,
	&-year-panel,
	&-month-panel {
		#{$prefix}-header {
			padding-bottom: 1rem;
		}

		#{$prefix}-cell-inner {
			height: 3rem;

			@include media-breakpoint-up(sm) {
				width: 3rem;
			}
		}
	}

	&-date-panel {
		#{$prefix}-year-btn {
			margin-left: 0.5rem;
		}

		#{$prefix}-header-super-prev-btn,
		#{$prefix}-header-super-next-btn {
			display: none;
		}

		#{$prefix}-content {
			th,
			#{$prefix}-cell {
				height: 2rem;
			}

			#{$prefix}-cell-inner {
				@include media-breakpoint-up(sm) {
					width: 2rem;
				}
			}
		}
	}

	&-focused + fieldset {
		border-color: $swg-tide !important;
		transition: all 0.3s ease;
	}

	&-input {
		position: relative;

		display: flex;
		flex: 1 1 auto;

		input {
			width: 100%;
			margin: 0;

			padding: 0.625rem 0.25rem 0.625rem 0.75rem;

			color: $swg-rock;
			font-size: 1rem;
			font-family: $font-family-base;

			border: 0;

			&:focus {
				outline: none;
			}

			&::placeholder {
				opacity: 1;
			}

			::placeholder {
				color: #bfbfbf;
			}

			#{$prefix}-input > input:placeholder-shown {
				text-overflow: ellipsis;
			}
		}
	}

	&-dropdown {
		position: absolute;
		z-index: 15;

		min-width: 100%;

		@include media-breakpoint-up(sm) {
			padding-top: 0.25rem;
		}

		@include media-breakpoint-only(xs) {
			position: fixed;
			top: 1rem !important;
			left: 50% !important;

			width: calc(100vw - 2rem);
			min-width: initial;

			transform: translateX(-50%);
		}

		@include media-breakpoint-up(sm) {
			border-radius: 0 0 4px 4px;
		}

		&-range {
			#{$prefix}-header {
				position: initial;

				&-prev-btn,
				&-next-btn {
					top: calc(0.75rem + 1px);
				}

				&-prev-btn {
					right: calc(2.75rem + 1px);
				}

				&-next-btn {
					right: calc(0.75rem + 1px);
				}

				&-view {
					text-align: left;

					@include media-breakpoint-up(sm) {
						padding-left: 1.5rem;
					}

					> button {
						cursor: default;
					}
				}
			}

			#{$prefix}-panel {
				@include media-breakpoint-up(sm) {
					#{$prefix}-date-panel {
						min-width: auto;
						padding-right: 0;
					}

					+ #{$prefix}-panel {
						margin-left: 1rem;

						#{$prefix}-date-panel {
							padding-right: 2.75rem;
							padding-left: 0;
						}

						#{$prefix}-header-view {
							padding-right: 1.5rem;

							text-align: right;
						}
					}
				}

				&:first-child {
					margin-bottom: 1rem;

					@include media-breakpoint-up(sm) {
						margin-bottom: 0;
					}
				}

				&-container {
					border: 0;
					box-shadow: none;
				}
			}

			#{$prefix}-content {
				@include media-breakpoint-up(sm) {
					width: auto;
				}
			}
		}

		&-hidden {
			display: none;
		}
	}

	&-range {
		@include media-breakpoint-up(sm) {
			#{$prefix}-active-bar {
				bottom: 0;
				z-index: 25;

				height: 3px;

				background: $swg-tide;
				opacity: 0;

				transition: all 0.3s;

				pointer-events: none;
			}

			&#{$prefix}-focused #{$prefix}-active-bar {
				opacity: 1;
			}
		}

		#{$prefix}-input {
			flex: 0 1 auto;
		}

		&-separator {
			align-self: center;

			+ #{$prefix}-input input {
				padding-right: 0.25rem;
				padding-left: 0.25rem;
			}
		}

		&-arrow {
			display: none;
		}

		&-wrapper {
			display: flex;
			flex: 1 0 auto;
			justify-content: center;
			min-width: auto !important;
			padding: 0.75rem;

			background-color: $swg-white;
			border: 1px solid $swg-tide;
			border-radius: 4px;
			box-shadow: 0 9px 16px rgba(#000, 0.16);

			@include media-breakpoint-up(sm) {
				position: relative;

				min-width: max-content !important;

				border-radius: 0 0 4px 4px;
			}
		}
	}

	&-disabled {
		input {
			color: $swg-pebble;

			background-color: transparent;
		}

		#{$prefix}-suffix {
			color: $swg-pebble;
		}
	}
}
