@import "../styles/variables";

.homeContent {
	h2,
	.homeHeading {
		color: $swg-rock;
	}

	ol,
	ul,
	.homeList {
		padding-left: 1rem;
	}

	a {
		color: $swg-tide;
		border: 0;
		background-color: transparent;
		padding: 0 !important;
		display: inline;
		font-size: 1rem;
		// Button over-ride
		min-height: auto;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}
