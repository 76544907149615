@import "../styles/variables";
@import "../styles/vendor/breakpoints";
@import "../styles/functions";

.verify {
  padding: 1.5rem 0.5rem;
  box-shadow: 0 0 0.8rem rgba($swg-black, 0.2);
  min-width: 300px;

  @include media-breakpoint-up(lg) {
    margin: auto 4rem;
  }

  @include media-breakpoint-down(sm) {
    box-shadow: none;
  }

  &Heading {
    font-size: 1.25rem;
    line-height: normal;
    margin-bottom: 0;
    color: $swg-rock;
  }

  .inputContainer {
    position: relative;
    margin-bottom: 0;
  
    &Label {
      position: absolute;
      top: -9999px;
      left: -9999px;
  
      &.required::after {
        color: $error;
        font-size: $font-size-base;
        line-height: 1;
    
        content: " *";
      }
    }
  
    .validationErrorMessage {
      display: block;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      color: $error;
      font-size: 0.95rem;
    }
  
    fieldset {
      top: 0;

      legend {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

    }
  
    label {
      display: block;
  
      .inputWrapper {
        position: relative;
  
        span {
          position: absolute;
          top: 50%;
          right: 1rem;
          color: $swg-pebble;
          font-size: 80%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
  
      input[type="text"] {
        display: block;
        width: 100%;
        height: 2.5rem;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-family: inherit;
        line-height: 1.5;
        background-color: $white;
        border: 1px solid $input-border-color;
        border-radius: 0.25rem;
        box-shadow: none;
        appearance: none;
        outline: none;

        &:hover:not([disabled]) {
          border-color: $swg-rock;
        }

        &:focus:not([disabled]) {
          border-color: $swg-tide;
        }

        &[readonly] {
          background-color: $swg-pearl;
          cursor: not-allowed;
        }
      }
  
    }

    .inputDatePicker {
      border-radius: 0.25rem;
      height: 2.5rem;

      > span {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      input {
        line-height: normal;
      }
    }
  }
  
  .checkboxGroup {
		display: inline-block;
		margin-right: 1rem;
    font-size: 0.85rem;
	}

  input[type="checkbox"] {
		position: fixed;
		top: -9999px;
		left: -9999px;

		width: 0;
		overflow: hidden;
	}

	input[type="checkbox"] + label {
		display: inline-flex;
		min-height: 2rem;
		padding-left: 0.7em;

		cursor: pointer;

		&::before {
			position: relative;
			top: 0;
			bottom: 0;
			left: 0;

			display: inline-block;
			width: 1em;
			height: 1em;
			margin: auto;
			margin-top: 0.25rem;
			margin-right: 0.5em;
			margin-left: -0.75rem;
			padding: 0.5rem;

			font-size: 1em;
			vertical-align: text-bottom;

			background-image: inline-svg("<svg width='1536' height='1536' viewBox='0 0 1536 1536' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path style='fill:" +
			$swg-dune + "' d='M0 0h1536v1536H0z'/><path style='fill:" + $swg-white +
			"' d='M77.5 77.5h1380.99v1380.99H77.5z'/></svg>");

			background-repeat: no-repeat;
			background-position: left center;
			background-size: contain;

			content: "";
		}
	}

	input[type="checkbox"]:checked + label {
		&::before {
			background-image: inline-svg("<svg width='1536' height='1536' viewBox='0 0 1536 1536' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path style='fill:" +
			$swg-tide + "' d='M0 0h1536v1536H0z'/><path style='fill:" + $swg-white +
			"' d='M685 1171l614-614q19-19 19-45t-19-45l-102-102q-19-19-45-19t-45 19L640 832 429 621q-19-19-45-19t-45 19L237 723q-19 19-19 45t19 45l358 358q19 19 45 19t45-19z'/></svg>");
		}
	}

	input[type="checkbox"]:hover + label, input[type="checkbox"]:focus + label {
		&::before {
			background-image: inline-svg("<svg width='1536' height='1536' viewBox='0 0 1536 1536' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path style='fill:" +
			$swg-tide + "' d='M0 0h1536v1536H0z'/><path style='fill:" + $swg-white +
			"' d='M77.5 77.5h1380.99v1380.99H77.5z'/></svg>");
			box-shadow: 0 0 0.2em rgba($swg-sun, 0.75);
		}
	}

	input[type="checkbox"]:checked:hover + label, input[type="checkbox"]:checked:focus + label {
		&::before {
			background-image: inline-svg("<svg width='1536' height='1536' viewBox='0 0 1536 1536' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path style='fill:" +
			$swg-tide + "' d='M0 0h1536v1536H0z'/><path style='fill:" + $swg-white +
			"' d='M685 1171l614-614q19-19 19-45t-19-45l-102-102q-19-19-45-19t-45 19L640 832 429 621q-19-19-45-19t-45 19L237 723q-19 19-19 45t19 45l358 358q19 19 45 19t45-19z'/></svg>");
			box-shadow: 0 0 0.2em rgba($swg-sun, 0.75);
		}
	}

	input[type="checkbox"]:disabled + label {
		&::before {
			background-image: inline-svg("<svg width='1536' height='1536' viewBox='0 0 1536 1536' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path style='fill:" +
			$swg-dune + "' d='M0 0h1536v1536H0z'/><path style='fill:" + $swg-pearl +
			"' d='M77.5 77.5h1380.99v1380.99H77.5z'/></svg>");
		}
	}

	input[type="checkbox"]:checked:disabled + label {
		&::before {
			background-image: inline-svg("<svg width='1536' height='1536' viewBox='0 0 1536 1536' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path style='fill:" +
			$swg-dune + "' d='M0 0h1536v1536H0z'/><path style='fill:" + $swg-pearl +
			"' d='M685 1171l614-614q19-19 19-45t-19-45l-102-102q-19-19-45-19t-45 19L640 832 429 621q-19-19-45-19t-45 19L237 723q-19 19-19 45t19 45l358 358q19 19 45 19t45-19z'/></svg>");
		}
	}

  .buttonWrapper {
    .verifyButton:disabled {
      cursor: no-drop;
      pointer-events: all;
      transition: none;
      opacity: 0.2;
      box-shadow: none;
    }
  }

  .requiredField {
    font-size: 0.8rem;
    margin: 1rem 0;
    position: relative;
    top: -0.75rem;
  }

  .legal {
    font-size: 0.85rem;
    line-height: 1.2;

    a {
      color: $swg-tide;
      
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }

  .backButtonContainer {
    margin-top: 0.25rem;

    .backButton {
      background: none;
      border: none;
      font-size: 0.9rem;
      padding: 0;
      line-height: normal;
      min-height: auto;
      color: $swg-tide;
  
      &:hover, &:focus {
        text-decoration: underline;
      }
    }

  }


  .hovered + fieldset {
    border-color: $swg-rock;
  }

  .note {
    font-size: 0.8rem;
    line-height: 1.2;
    display: inline-block;
    font-style: italic;

    p {
      margin-bottom: 0;
    }
  }
}


