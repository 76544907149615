@import "../../styles/variables";
@import "../../styles/vendor/breakpoints";

.inputGroup {
	align-items: stretch;

	background: $swg-white;

	@include media-breakpoint-up(sm) {
		&Open::after {
			position: absolute;
			top: calc(100% - 0.5rem + 1px);
			left: 0;
			z-index: 20;

			display: block;
			width: 100%;
			height: 1rem;

			background-color: $swg-white;
			border: 1px solid $swg-tide;
			border-top: 0;
			border-bottom: 0;

			content: "";
		}
	}
}
