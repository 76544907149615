/*
.guide {

  ul {
    list-style: auto;
  }

  li {
    line-height: 2;
  }

  img {
    padding-top: 0.5rem;
    width: 100%;
  
    &.image25 {
      width: 25%;
    }
  
    &.image50 {
      width: 50%;
    }
  
    &.image75 {
      width: 75%;
    }
  
    @media (max-width: 767px) {
      width: 100%;
    }
  }

}
*/

.guide ul {
  list-style-type: auto;
}

.guide ul.disc {
  list-style-type: disc;
}

.guide li {
  line-height: 2;
}

.guide a {
  text-decoration: none;
  color: #005b9e;
}

.guide a:hover, .guide a:focus {
  text-decoration: underline;
}

.guide img {
  padding-top: 0.5rem;
  width: 100%;
}

.guide .image25 {
  width: 25%;
}

.guide .image50 {
  width: 50%;
}

.guide .image75 {
  width: 75%;
}

@media (max-width: 767px) {
  .guide img {
    width: 100% !important;
  }
}