@import "../styles/variables";
@import "../styles/mixins";

.parent {
	display: flex;
	padding-top: 3rem !important;

	.loader {
		display: flex;
		flex: 1 1 auto;
		min-height: 50vh;
		align-items: center;

		font-size: 2rem;
	}
}


.alertMessage {
	position: relative;

	margin-top: 1rem;

	border: 0;
	border-radius: 0.25rem;

	> button[type="button"] {
		top: 0.5rem;
		right: 1rem;

		display: none;
		flex: 0 0 auto;
		margin-top: 0.25rem;
		padding: 0;

		color: $swg-rock !important;
		font-size: 1rem;
	}

	.headingContainer {
		display: flex;
		align-items: flex-start;

		.headingIcon {
			left: 0;

			margin-top: 0.25rem;

			color: $error;
			font-size: 1.25rem;
		}

		.headingMessage {
			flex: 1 1 auto;
			padding: 0 2rem 0 1rem;

			color: $error;
			font-weight: $font-weight-base;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	// Alert type over-rides

	&.success {
		background-color: $light-success !important;

		.headingIcon {
			color: $success;
		}

		.headingMessage {
			color: $success;
		}
	}

	&.error {
		background-color: $light-error !important;

		.headingIcon {
			color: $error;
		}

		.headingMessage {
			color: $error;
		}
	}

	&.warning {
		background-color: $light-warning !important;

		.headingIcon {
			color: $warning;
		}

		.headingMessage {
			color: $warning;
		}
	}
}

.button {
	background: none;
	@include btn-reset();

	padding: 0 !important;
	color: $swg-tide;

	&:hover {
		text-decoration: underline;
		box-shadow: none;
	}
}