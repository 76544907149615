@import "~@okta/okta-signin-widget/css/okta-sign-in.min.css";
@import "../../../styles/variables.scss";
@import "../../../styles/vendor/breakpoints";

.sso-widget.classic #okta-sign-in {
	width: auto;
	min-width: 300px;

	input[type="password"]::-ms-reveal,
	input[type="password"]::-ms-clear {
		display: none;
	}

	&.auth-container .auth-content {
		padding: 25px;

		.auth-footer {
			font-size: 0.9rem;
		}

	}

	&.auth-container.main-container {
		margin: auto;
		font-family: $font-family-base;
		border: 0;
		border-radius: 0.3rem;
		outline: none;
		box-shadow: 0 0 0.8rem rgba($swg-black, 0.2);

		@include media-breakpoint-up(lg) {
			margin: auto 4rem;
		}
	}

	.okta-form-infobox-error {
		display: flex !important;
	}

	&.auth-container .button-primary {
		color: $swg-white;

		background: transparent;
		background-color: $swg-tide;
		border-radius: 0.25rem !important;
		text-decoration: none;
	}

	&.auth-container .button-primary:hover {
		box-shadow: inset 0 0 400px 110px rgba($swg-black, 0.2);
	}

	&.auth-container .button-primary:focus {
		box-shadow: 0 0 6px $swg-sun;
	}

	.okta-sign-in-header.auth-header {
		display: none;
	}

	.o-form-explain.o-form-input-error,
	.okta-form-infobox-error {
		color: $error;
	}

	.o-form-explain.o-form-input-error .icon::before {
		color: $error;
	}

	.help-wrap .infobox.infobox-error::before,
	.infobox-error::before,
	.infobox.infobox-subtle.infobox-error::before {
		background-color: $error;
	}

	.okta-form-input-field:hover, .okta-form-input-field input:focus {
		border-color: $swg-tide;
	}

	.focused-input,
	.focused-input input,
	input,
	.link.help:focus {
		box-shadow: none;

		@media (max-width: 767px) {
			font-size: 1rem !important;
		}
	}

	.okta-form-title.o-form-head,
	.okta-form-label,
	label {
		color: $swg-rock;
	}

	.okta-form-title.o-form-head {
		margin-top: 0;

		font-weight: $font-weight-bold;
		font-size: 1.25rem;
		line-height: 1;
		text-align: left;
	}

	&.auth-container .link,
	&.auth-container .link:active,
	&.auth-container .link:hover,
	&.auth-container .link:link,
	&.auth-container .link:visited,
	&.auth-container .back-btn {
		color: $swg-tide;
		font-size: rem(14px);
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.registration .subschema .subschema-satisfied {
		color: $swg-rock;
	}

	.error-16-red::before,
	.error-16-small::before {
		color: $error;
	}

	.registration .subschema .subschema-error {
		color: $error;
	}

	.registration .o-form-fieldset-container .required-fields-label {
		color: $swg-rock;
		padding-bottom: 11px;
    display: inline-block;
	}

	.registration-container .registration-label,
	.auth-divider .auth-divider-text {
		color: $swg-rock;
	}

	.registration-container .registration-link {
		color: $swg-tide;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.legal {
		display: block;
		padding-top: 1rem !important;
		color: $swg-rock;
		font-style: italic !important;

		a {
			color: $swg-tide;
			text-decoration: underline;
		}
	}

	/* Sign up section */
	.registration-container {
		margin-top: 15px;
		display: none;

		&.show {
			display: block;
		}
	}

	.registration-container .content-container {
		padding: 0;

		border-top: 0;
	}

	@media (max-width: 767px) {
		&.auth-container .auth-content {
			max-width: 100%;
			padding: 15px;
		}

		.sso-widget {
			min-height: auto;
			padding: 0;
		}

		&.auth-container.main-container {
			border-radius: 0;
			box-shadow: none;
		}
	}

	.registration {
		// Custom consent field and label
		.o-form-fieldset-container .o-form-fieldset .custom-checkbox label {
			min-height: 2rem;
		}

		.o-form-explain.o-form-input-error {
			word-break: normal;
		}
	}

	.registration-complete .icon::before {
		line-height: unset;
	}

	// Okta Sign-in widget CSS updates for Social Media
	.primary-auth-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;

		& > a.social-auth-button.link-button {
			flex: 0 1 50px;
			max-width: 50px;
			padding-left: 0;
			border-radius: 2rem;
			transition: all 1s ease-out;

			&::after {
				position: absolute;
				width: 3rem;
				height: 3rem;
				background-color: rgba($swg-rock, 0);
				border-radius: inherit;
				transition: all 0.3s ease-out;
				content: "";
			}

			&:hover,
			&:focus {
				border-color: $swg-rock;
				transition: all 1s ease-out;

				&::after {
					position: absolute;
					width: 3rem;
					height: 3rem;
					background-color: rgba($swg-rock, 0.1);
					border-radius: inherit;
					transition: all 0.3s ease-out;
					content: "";
				}
			}
		}
		
		.auth-divider {
			flex-basis: 100%;
		}
	}

	.okta-sign-in-header.activate {
		display: inline-block;
		width: 100%;
		padding: 0;

		.account-activation {
			padding: 1rem 15px;

			text-align: center;

			background-color: $light-success;

			.heading {
				display: inline-flex;
				align-items: center;

				color: $success;
				font-weight: 700;
				font-size: 1.2rem;

				.swg-icon {
					margin-right: 0.5rem;

					font-size: 1.5rem;
					font-family: "swg-icons";
				}
			}

			.message {
				font-weight: $font-weight-base;
				font-size: 0.9rem;
			}

			&.verify {
				text-align: left;

				.swg-icon {
					margin-right: 1rem;
				}

				.message {
					margin-left: 2.5rem;
				}
			}
		}
	}
}

// Added widget targeting for CLS fix on Login component
.sso-widget-wrapper {
	@media (min-width: 601px) {
		min-height: 670px;
	}
	@media (max-width: 600px) {
		min-height: 510px;
	}
}

/* Work-around to keep widget background under header */
@at-root header.page__header {
	position: relative;
}

// Target header z-index issue
.page__header {
	position: relative;
}

// Handle usecase when widget modal is displayed on login page and user cannot close overlay
.sso-disable-close {
	display: none;
}
