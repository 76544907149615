@import "../styles/variables";
@import "../styles/vendor/breakpoints";

// Expand main container to occupy 100% page height
[id="root"] {
	min-height: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	[class="loader"] {
		display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    min-height: inherit;
	}
}

main.mainBody {
	flex-grow: 1;
	padding-top: 0.25rem;
}

.header {
	@include media-breakpoint-up(md) {
		min-height: 82px;
	}

	[class*="headerImage"] {
		min-width: 250px;
	}

	[class*="headerWrapper"] {
		padding: 1rem 0 0.875rem;
	}

	@include media-breakpoint-down(sm) {
		[class*="headerImage"] {
			min-width: 100%;
			position: relative;
			top: 0.1rem;
		}

		[class*="headerSecondaryNav"] {
			position: relative;
			top: -0.25rem;
		}
	}
}

.accountMenu {
	margin: 0;
	padding-left: 0;

	list-style: none;

	.textIndent {
		display: inline-block;
		padding-left: 0.5rem;
	}

	.myAccount .icon {
		font-size: 0.75rem;
	}

	.icon {
		display: inline-block;

		vertical-align: bottom;
	}

	.divider {
		border-top: 1px solid rgba($swg-black, 0.15);
	}

	li.listItem > a,
	li.listItem > button {
		display: block;
		padding: 0.25rem 1rem;
		cursor: pointer;
		color: $swg-rock;
		background-color: $swg-white;

		&:hover,
		&:focus {
			background-color: $swg-pearl;
		}

		&:focus {
			outline: 5px auto -webkit-focus-ring-color;
		}
	}

	li.listItem > button.myAccount {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 0.5rem 1rem !important;
		font-size: 1rem;
		background-color: $swg-white;
		border: 0;
		white-space: nowrap;
		text-align: left;
		text-decoration: none;
		
		&:hover,
		&:focus {
			background-color: $swg-pearl;
		}

		> span:first-of-type {
			margin: 0;
		}
	}

	li.listItem > button.myAccount {
		> span:first-of-type {
			font-size: 0.75rem;
		}
	}

	li.listItem:first-of-type > a {
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}

	li.listItem:last-of-type > button {
		border-bottom-right-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}

	li.listItem > button.logout {
		width: 100%;
		padding: 0.5rem 1rem;
		color: $swg-rock;
		font-size: 1rem;
		text-align: left;
		border: 0;

		@include media-breakpoint-down(sm) {
			border-radius: 0;
		}

		&:focus,
		&:hover {
			background-color: $swg-pearl;
		}
	}
}
