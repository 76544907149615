@font-face {
	font-weight: 400;
	font-family: "MullerNarrow";
	font-style: normal;
	src: url("./MullerNarrow-Medium/font.woff") format("woff");
}

@font-face {
	font-weight: 700;
	font-family: "MullerNarrow";
	font-style: normal;
	src: url("./MullerNarrow-Bold/font.woff2") format("woff2"),
		url("./MullerNarrow-Bold/font.woff") format("woff");
}
