@import "../styles/variables";

.banner {
    padding: 16px 24px 16px 24px;
    background: $swg-royal;
    color: $swg-white;
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 1000;

    .bannerContainer{
        display: flex;
        justify-content: center;
        align-items: center;

        .label {
            display: flex;
            justify-content: center;
        }   
        
        .text {
             margin-right: 30px;
             p {
                margin: 0;
            }   
        }
    
        .acceptBtn {
            border: 1px solid $swg-white;
            background-color: $swg-royal;
            width: 93px;
            height: 41px;
            font-size: 16px;
            font-family: PT Sans, Regular;
            color:  $swg-white;
            font-weight: 600;
            &:hover {
               box-shadow: unset;
            }
            &:after {
            background-color: $swg-royal;
            }
        }
    }
}

@media (max-width: 600px) {
    .banner {
         padding-top: 24px;
         padding-bottom: 24px;
        .bannerContainer{ 
            flex-direction: column;
            justify-content: initial;

            .text {
                width: 100%;
                margin-bottom: 16px;
                margin-right: initial;
            }
            .acceptBtn {
                width: 100%;
            }
        } 
    }
  }
