@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/functions";

$fieldset-label-padding: 5px;

.container {
	position: relative;
	display: inline-block;
	background-color: $swg-white;
	border-radius: rem(4px);

	&.filters {
		margin-top: 0;
	}
}

.label {
	position: absolute;
	top: -5px;
	left: 7px + $fieldset-label-padding;
	z-index: 5;

	display: block;
	padding: 0;

	color: $swg-tide;
	font-weight: 700;
	font-size: rem(13px);
	line-height: 1;
}

.filtersLabel {
	position: absolute;
	top: -0.3125rem;
	left: 0.3rem;
	z-index: 5;

	display: block;
	padding: 0 0.25rem;

	color: #005b9e;
	font-weight: 700;
	font-size: 0.8125rem;
	line-height: 1;

	background-color: #fff;
}

.input > input {
	width: 100%;

	min-width: 0;
	margin: 0;
	padding: rem(10px) 0.75rem;

	border: 0;

}

.container:not(.filters) {
	.label {
		&:focus {
			outline: none;
		}
	}
}

.fieldset {
	position: absolute;
	top: -5px;
	right: 0;
	bottom: 0;
	left: 0;

	margin: 0;
	padding: 0 rem(7px);
	overflow: hidden;

	border: 1px solid $swg-pebble;
	border-radius: inherit;

	pointer-events: none;

	legend {
		display: block;
		width: auto;
		height: 11px;
		padding: 0;

		font-size: 0.75em;
		text-align: left;

		> span {
			display: inline-block;
			padding: 0 rem($fieldset-label-padding);
		}
	}
}

.focused {
	// box-shadow: 0 9px 16px #00000029;

	.fieldset {
		border-color: $swg-tide;
	}
}

.error:not(.focused) {
	.label {
		color: $error;
	}

	.fieldset {
		border-color: $error;
	}
}

.errorMessage {
	color: $error;
}
