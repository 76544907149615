@import "../styles/variables";
@import "../styles/vendor/breakpoints";

.link {
    color: $swg-tide;
    border: 0;
    background-color: transparent;
    padding: 0 !important;
    display: inline;
    font-size: 1rem;
    // Button over-ride
    min-height: auto;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.modalOverlay {
    display: flex;
    align-items: center;
}

.modalInner {
	padding: 1.5rem;
	border: 1px solid $swg-pebble;
	margin: 0.75rem;

    @include media-breakpoint-up(sm) {
        padding: 3.125rem;
    }

	p {
		margin: 0 0 1.875rem;
	}
}

.modalBtn {
    width: 50%;
	max-width: 140px;

	&:first-of-type {
		margin-right: 0.625rem;
        width: calc(50% - 0.625rem);
	}
}