@import "../styles/variables";
@import "../styles/vendor/breakpoints";

.customFooter {
	padding: 2.5rem 0;
	
	.footerLinks {
		margin-bottom: 1rem;
		background-color: transparent;
		color: $swg-white;
		border: 0;
		font-size: 1rem;
		margin-right: 1rem;
		
		&:last-child {
			@include media-breakpoint-up(md) {
				margin-right: 3rem;
			}
		}

		&:hover {
			text-decoration: underline;
		}
	}
}
