$booking-search-box-padding-top: 0.75rem;
$booking-search-box-padding-bottom: 0.75rem;
$booking-search-box-types-heights: (
	packages: (
		xs: rem(304px),
		sm: rem(168px),
		md: rem(112px),
		lg: rem(48px),
	),
	flights: (
		xs: rem(336px),
		sm: rem(200px),
		md: rem(112px),
		lg: rem(48px),
	),
	hotels: (
		xs: rem(240px),
		sm: rem(112px),
		lg: rem(48px),
	),
	cruises: (
		xs: rem(368px),
		sm: rem(176px),
		lg: rem(112px),
		xl: rem(48px),
	),
);

.bar {
	padding: $booking-search-box-padding-top 0 $booking-search-box-padding-bottom;
}

@each $type, $sizes in $booking-search-box-types-heights {
	.#{$type} {
		@each $size, $height in $sizes {
			// @include media-breakpoint-up($size) {
			// 	min-height: $height + $booking-search-box-padding-top +
			// 		$booking-search-box-padding-bottom;

			// 	.placeholder {
			// 		min-height: $height;
			// 	}
			// }
		}
	}
}

.placeholder {
	position: relative;

	&::before {
		position: absolute;
		top: 0;
		bottom: 0;

		display: block;
		width: 100%;

		background-image: url("./placeholder.svg");
		background-repeat: round;

		content: "";
	}
}
