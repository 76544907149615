@import "../styles/variables";

.errorPage {
    position: relative;
    text-align: center;

    &Img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        min-height: 100%;
        height: calc(100vh - 223px);
        object-fit: cover;
        object-position: center center;
        z-index: -1;
    }

    &Heading {
        padding-top: 10rem; 
        text-align: center;


        span {
            color: $swg-tide;
            font-size: 54px;

            &:first-child {
                color: $swg-sun;
            }

            &:nth-child(2) {
                color: $swg-coral-reef;
            }

            &:nth-child(3) {
                color: #e0246f;
            }

            &:nth-child(4) {
                color: $swg-coral-reef;
            }

            &:nth-child(5) {
                color: #fb344d;
            }
        }
    }
    &Subheading {
        font-size: 24px;
        text-align: center;
    }

    &ReturnLink {
        color: $swg-tide;
    }

}